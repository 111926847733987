// utils/instagramUtils.js
import moment from 'moment';


const cloudName = 'latest-sightings-upload';

const extractVideoId = (videoUrl) => {
  const parts = videoUrl.split('/upload/');
  if (parts.length > 1) {
    return parts[1]; // Everything after 'upload/'
  }
  return null;
};

const getRandomPositionAndOffsets = () => {
  const positions = ['north_west', 'north_east', 'south_west', 'south_east'];
  const randomIndex = Math.floor(Math.random() * positions.length);
  const position = positions[randomIndex];
  return position;
};


const pluralizeAnimal = (animal, count) => {
  if (count > 1) {
    return animal + 's'; // Simple pluralization by adding 's'
  }
  return animal;
};


const getDateDescriptor = (timestamp) => {
  const tingDate = moment.unix(timestamp.seconds); // Convert seconds to moment object
  const now = moment();

  const diffDays = now.diff(tingDate, 'days');

  if (diffDays === 0) return 'today';
  if (diffDays === 1) return 'yesterday';
  return `${diffDays} days ago`;
};


export const generateCaption = (animal, roadname, username, animalCount, timestamp) => {
  const animalName = pluralizeAnimal(animal, animalCount);
  const dateDescriptor = getDateDescriptor(timestamp);
  return `${animalName} ${dateDescriptor} on the ${roadname}%0ATinged by ${username}`;
};

const addTextOverlayToVideo = (videoUrl, overlayText) => {
  console.log('Starting text overlay process for video:', videoUrl);
  const videoId = extractVideoId(videoUrl);
  console.log('Extracted video ID:', videoId);

  if (!videoId) {
    console.error('Invalid video URL:', videoUrl);
    return null;
  }

  const baseUrl = `https://res.cloudinary.com/${cloudName}/video/upload/`;

  const position = getRandomPositionAndOffsets();

  // Define transformations
  const transformations = [
    'a_-0',
    'b_rgb:ee9247',
    'bo_5px_solid_rgb:ee9247',
    'c_scale',
    'fl_relative',
    'w_0.5',
    `g_${position}`,
    `l_text:Arial_55:${encodeURIComponent(overlayText)}`,
    'o_100',
    `x_20`,
    `y_0.08`,
  ];

  const transformationString = transformations.join(',');

  const finalUrl = `${baseUrl}${transformationString}/${videoId}`;
  console.log('Generated Cloudinary URL with text overlay:', finalUrl);
  return finalUrl;
};



export const postToInstagramStories = async (mediaUrl, mediaType, animal, roadname, username, animalCount, timestamp, customCaption) => {
  
  console.log('Starting Instagram story posting process');
  console.log('Media URL:', mediaUrl);
  console.log('Media Type:', mediaType);
  console.log('Animal:', animal);
  console.log('Roadname:', roadname);
  console.log('Username:', username);
  console.log('Timestamp:', timestamp);

  const caption = customCaption || generateCaption(animal, roadname, username, animalCount, timestamp);

  console.log('Generated Caption:', caption);

  const accessToken = 'EAASzWKK4MCQBO28ZCXkAOZCGaGUyX1BqnRYwfVcpc0VdBXxS1rRZAzeNgt5ojloZANgrRMgQraYZBhXOHBVjvU3PcdpaC5NBsuLO0fzZB2yKhHToXAbWb4rMNk7IkbWJHQ8ffComZBMxVAiTwXU6QAdwox1pEZBqVRwrrMOqsLhTWpKg30irZAnXAXkNiD5lYBZAZANkMjZBUgZDZD'
  const igUserId = '17841401271707334'; // Your Instagram User ID
  const apiVersion = 'v20.0'; // API version, ensure it is up-to-date

  if (mediaType === 'video') {
    console.log('Adding text overlay to video');
    mediaUrl = addTextOverlayToVideo(mediaUrl, caption);
    console.log('Updated media URL with text overlay:', mediaUrl);
  }

  const createMediaContainer = async () => {
    console.log('Creating media container');
    let params = {
      access_token: accessToken,
      media_type: 'STORIES',
    };

    if (mediaType === 'image') {
      params.image_url = mediaUrl;
      console.log('Set image URL for media container:', mediaUrl);
    } else if (mediaType === 'video') {
      params.video_url = mediaUrl;
      console.log('Set video URL for media container:', mediaUrl);
    } else {
      console.error('Unsupported media type:', mediaType);
      alert('Unsupported media type.');
      return;
    }

    try {
      const response = await fetch(`https://graph.facebook.com/${apiVersion}/${igUserId}/media`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      const data = await response.json();
      console.log('Response from creating media container:', data);

      if (!data.id) {
        console.error('Error creating Instagram media container:', data);
        alert('Error creating Instagram media container.');
        return null;
      }

      return data.id;
    } catch (error) {
      console.error('Error creating Instagram media container:', error);
      alert('Error creating Instagram media container.');
      return null;
    }
  };

  const checkMediaStatus = async (mediaId) => {
    console.log('Checking media status for ID:', mediaId);
    try {
      const response = await fetch(`https://graph.facebook.com/${apiVersion}/${mediaId}?fields=status_code&access_token=${accessToken}`);
      const data = await response.json();
      console.log('Response from checking media status:', data);

      return data.status_code;
    } catch (error) {
      console.error('Error checking Instagram media status:', error);
      return null;
    }
  };

  const publishMediaContainer = async (mediaId) => {
    console.log('Publishing media container with ID:', mediaId);
    try {
      const response = await fetch(`https://graph.facebook.com/${apiVersion}/${igUserId}/media_publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          creation_id: mediaId,
          access_token: accessToken,
        }),
      });

      const data = await response.json();
      console.log('Response from publishing media container:', data);

      if (data.id) {
        console.log('Instagram story posted successfully:', data.id);
        alert('Instagram story posted successfully!');
      } else {
        console.error('Error publishing Instagram story:', data);
        alert('Error publishing Instagram story.');
      }
    } catch (error) {
      console.error('Error publishing Instagram story:', error);
      alert('Error publishing Instagram story.');
    }
  };

  const mediaId = await createMediaContainer();
  if (!mediaId) return;

  if (mediaType === 'video') {
    let status = 'IN_PROGRESS';
    while (status !== 'FINISHED' && status !== 'ERROR') {
      console.log('Current media status:', status);
      await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds before checking status again
      status = await checkMediaStatus(mediaId);
    }

    if (status === 'ERROR') {
      console.error('Error processing Instagram video.');
      alert('Error processing Instagram video.');
      return;
    }
  }

  await publishMediaContainer(mediaId);
};

import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import dayjs from 'dayjs';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const Dashboard = () => {
  const [dailySubscribers, setDailySubscribers] = useState([]);
  const [monthlySubscribers, setMonthlySubscribers] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [projections, setProjections] = useState({});
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const premiumSubscribersRef = collection(db, 'premiumSubscribers');
      const snapshot = await getDocs(premiumSubscribersRef);

      const data = snapshot.docs.map((doc) => doc.data());
      console.log('Fetched data:', data);
      processMetrics(data);
    };

    const processMetrics = (data) => {
      const dailyCounts = {};
      const monthlyCounts = {};
      const monthlyRevenue = {};
      const today = dayjs();

      data.forEach((subscriber) => {
        if (!subscriber.startDate || !subscriber.startDate.seconds) return;

        const startDate = dayjs.unix(subscriber.startDate.seconds);
        const month = startDate.format('YYYY-MM');
        const day = startDate.format('YYYY-MM-DD');
        const amount = subscriber.billingHistory?.[0]?.amount || 0;

        // Daily subscribers
        dailyCounts[day] = (dailyCounts[day] || 0) + 1;

        // Monthly subscribers
        monthlyCounts[month] = (monthlyCounts[month] || 0) + 1;

        // Monthly revenue
        monthlyRevenue[month] = (monthlyRevenue[month] || 0) + amount;
      });

      setDailySubscribers(dailyCounts);
      setMonthlySubscribers(monthlyCounts);
      setMonthlyRevenue(monthlyRevenue);

      // Generate table data
      const sortedMonths = Object.keys(monthlyRevenue)
        .sort()
        .map((month) => ({
          month,
          revenue: monthlyRevenue[month],
        }));

      const tableData = sortedMonths.map((item, index) => {
        const previousRevenue = index > 0 ? sortedMonths[index - 1].revenue : 0;
        const change =
          previousRevenue > 0
            ? ((item.revenue - previousRevenue) / previousRevenue) * 100
            : null;

        const formattedMonth = dayjs(item.month + '-01').format('MMM - YYYY');

        // Format revenue and change
        const formattedRevenue = new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(item.revenue);

        const formattedChange =
          change !== null
            ? `${change.toFixed(2)}%`
            : '-';

        return {
          month: formattedMonth,
          revenue: formattedRevenue,
          change: formattedChange,
        };
      });

      setTableData(tableData);

      // Calculate projections
      const currentMonth = today.format('YYYY-MM');
      const daysInMonth = today.daysInMonth();
      const avgDailyRevenue = (monthlyRevenue[currentMonth] || 0) / today.date();
      const projectedRevenue = avgDailyRevenue * daysInMonth;

      // Format projected revenue
      const formattedProjectedRevenue = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(projectedRevenue);


      setProjections({
        projectedRevenue: formattedProjectedRevenue,
        projectedPeriod: `for ${today.format('MMMM YYYY')}`,
      });
    };

    fetchData();
  }, []);

  const sortDataByDate = (data) => {
    const sortedKeys = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
    return {
      labels: sortedKeys,
      values: sortedKeys.map((key) => data[key]),
    };
  };

  const dailyData = sortDataByDate(dailySubscribers);
  const monthlySubscribersData = sortDataByDate(monthlySubscribers);
  const monthlyRevenueData = sortDataByDate(monthlyRevenue);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

      {/* Graphs Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="bg-white p-4 shadow rounded">
          <h2 className="text-lg font-semibold mb-2">Daily Subscribers</h2>
          <Bar
            data={{
              labels: dailyData.labels,
              datasets: [
                {
                  label: 'Subscribers',
                  data: dailyData.values,
                  backgroundColor: '#4F46E5',
                },
              ],
            }}
            options={{
              plugins: { legend: { display: false } },
              scales: { x: { ticks: { font: { size: 10 } } } },
            }}
          />
        </div>

        <div className="bg-white p-4 shadow rounded">
          <h2 className="text-lg font-semibold mb-2">Monthly Subscribers</h2>
          <Bar
            data={{
              labels: monthlySubscribersData.labels,
              datasets: [
                {
                  label: 'Subscribers',
                  data: monthlySubscribersData.values,
                  backgroundColor: '#10B981',
                },
              ],
            }}
            options={{
              plugins: { legend: { display: false } },
              scales: { x: { ticks: { font: { size: 10 } } } },
            }}
          />
        </div>

        <div className="bg-white p-4 shadow rounded">
          <h2 className="text-lg font-semibold mb-2">Monthly Revenue</h2>
          <Bar
            data={{
              labels: monthlyRevenueData.labels,
              datasets: [
                {
                  label: 'Revenue (R)',
                  data: monthlyRevenueData.values,
                  backgroundColor: '#F59E0B',
                },
              ],
            }}
            options={{
              plugins: { legend: { display: false } },
              scales: { x: { ticks: { font: { size: 10 } } } },
            }}
          />
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-white p-4 shadow rounded mb-6">
        <h2 className="text-lg font-semibold mb-2">Monthly Revenue Table</h2>
        <table className="w-full table-auto border-collapse text-sm">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-2 py-1">Month</th>
              <th className="border px-2 py-1">Revenue (R)</th>
              <th className="border px-2 py-1">Change (%)</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                <td className="border px-2 py-1">{row.month}</td>
                <td className="border px-2 py-1">R{row.revenue}</td>
                <td
                  className={`border px-2 py-1 ${row.change.startsWith('-') ? 'text-red-500' : 'text-green-500'
                    }`}
                >
                  {row.change}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Projections Section */}
      <div className="bg-white p-4 shadow rounded">
        <h2 className="text-lg font-semibold mb-2">Projections</h2>
        <p>
          Projected Revenue: <strong>R{projections.projectedRevenue}</strong> ({projections.projectedPeriod})
        </p>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import './Settings.css';

const Settings = () => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCanceling, setIsCanceling] = useState(false); // Loading state for cancel button
  const [targetUserId, setTargetUserId] = useState('');
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const q = query(collection(db, 'users'), where('bankAccountNumber', '>', ''));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        list.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setAccounts(list);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet('your export sheet name');
  worksheet.columns = [
    { header: 'First Name', key: 'firstName' },
    { header: 'Last Name', key: 'lastName' },
    { header: 'Email', key: 'email' },
    { header: 'Bank Name', key: 'bankAccountName' },
    { header: 'Account Number', key: 'bankAccountNumber' },
    { header: 'Branch Code', key: 'bankBranchCode' },
    { header: 'Paypal', key: 'paypalEmail' },
    { header: 'User Id', key: 'uid' },
  ];
  worksheet.getRow(1).font = { bold: true };

  const getData = async () => {
    let list = [];
    const q = query(collection(db, 'users'), where('bankAccountNumber', '>', ''));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    const q2 = query(collection(db, 'users'), where('paypalEmail', '>', ''));
    const querySnapshot2 = await getDocs(q2);
    querySnapshot2.forEach((doc) => {
      list.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    const uniqueIds = [...new Set(list.map((item) => item.id))];
    const uniqueList = uniqueIds.map((id) => list.find((item) => item.id === id));
    uniqueList.forEach((item) => {
      worksheet.addRow({ ...item });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'fileName' + fileExtension);
  };

  const functions = getFunctions();
  const cancelPayfastSubscription = httpsCallable(functions, 'payfast-cancelUserSubscription');

  const handleCancelSubscription = async () => {
    if (!targetUserId) {
      alert('Please enter a user ID.');
      return;
    }

    setIsCanceling(true);

    try {
      const result = await cancelPayfastSubscription({ userId: targetUserId });
      console.log('Subscription canceled:', result.data);
      alert(result.data.message || 'Subscription canceled successfully.');
    } catch (err) {
      console.error('Error canceling subscription:', err);
      alert(err.message || 'Error canceling subscription.');
    } finally {
      setIsCanceling(false);
    }
  };

  return (
    <div className='account__container'>
      <h1 className='account__heading'>Settings</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <p>User email: {user && user.email}</p>
          <Button variant="contained" onClick={getData} style={{ marginBottom: '1rem' }}>
            Export bank accounts to Excel
          </Button>
          <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <label>Cancel Subscription for User ID:</label>
            <input
              type="text"
              value={targetUserId}
              onChange={(e) => setTargetUserId(e.target.value)}
              placeholder="Enter userId..."
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancelSubscription}
              disabled={isCanceling}
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              {isCanceling ? (
                <>
                  <CircularProgress size={20} />
                  Canceling...
                </>
              ) : (
                'Cancel Subscription'
              )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
